var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import create_post_plus_icon from "../../../assets/img/create_post_plus_icon.svg";
import create_post_event_icon from "../../../assets/img/create_post_event_icon.svg";
import create_post_news_icon from "../../../assets/img/create_post_news_icon.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "../../../redux/store";
import { setActive, setModal } from "../../../redux/modalReducer";
import style from "./modalCreatePostMini.module.css";
import { setTypeMaterial } from "../../../redux/newsReducer";
import { useSelector } from "react-redux";
export function ModalCreatePostMini(_a) {
    var isActive = _a.isActive;
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var role = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.role; });
    var sortArrayButton = function (button, role) {
        if (role === 2) {
            return button.filter(function (el) { return el.id === 1; });
        }
        else {
            return button;
        }
    };
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var buttons = [
        {
            id: 1,
            title: "Создать публикацию",
            img: create_post_plus_icon,
        },
        {
            id: 2,
            title: "Создать событие",
            img: create_post_event_icon,
        },
        {
            id: 3,
            title: "Создать вакансию",
            img: create_post_news_icon,
        },
    ];
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    var OpenNewEditorModal = function (typeMaterial) {
        dispatch(setActive(false));
        setTimeout(function () {
            dispatch(setTypeMaterial(typeMaterial));
            dispatch(setModal("text-editor"));
            dispatch(setActive(true));
        }, 100);
    };
    var ckickItem = function (id) {
        switch (id) {
            case 1:
                navigate("/create");
                dispatch(setTypeMaterial(4));
                break;
            case 2:
                navigate("/create");
                dispatch(setTypeMaterial(2));
                break;
            case 3:
                navigate("/vacancy/Create");
                break;
        }
        handleCloseModal();
    };
    return (isActive && (_jsx(_Fragment, { children: isMobile ? (_jsx("div", __assign({ onClick: function () { return handleCloseModal(); }, className: style.modalMini }, { children: _jsx("div", __assign({ className: style.linkWrapper }, { children: buttons.map(function (item) { return (_jsxs("p", __assign({ onClick: function () {
                        ckickItem(item.id);
                    }, className: style.link }, { children: [_jsx("img", { className: style.icon, src: item.img, alt: "icon" }), _jsx("span", { children: item.title })] }), item.id)); }) })) }))) : (_jsx("div", __assign({ className: style.modalMini }, { children: _jsx("div", __assign({ className: style.linkWrapper }, { children: sortArrayButton(buttons, role).map(function (item) { return (_jsxs("p", __assign({ onClick: function () {
                        ckickItem(item.id);
                    }, className: style.link }, { children: [_jsx("img", { className: style.icon, src: item.img, alt: "icon" }), _jsx("span", { children: item.title })] }), item.id)); }) })) }))) })));
}
