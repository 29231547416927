export var StyleForErrorModalMaterial = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    wigth: '354px',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    padding: '20px',
    textAlign: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
};
