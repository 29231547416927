var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import style from "./ModalCreatePostMain.module.css";
import create_post_icon from "../../../assets/img/plus_icon_black.png";
import create_post_blog from "../../../assets/img/create_post_blog_icon.png";
import create_post_event from "../../../assets/img/create_post_event_icon.png";
import create_post_news from "../../../assets/img/create_post_news_icon.png";
import { useAppDispatch } from "../../../redux/store";
import { setActive } from "../../../redux/modalReducer";
import { ModalBlurWrapper } from "../../Helpers/ModalBlurWrapper/ModalBlurWrapper";
import close_icon from "../../../assets/img/close_modal_icon.png";
var buttons = [
    { id: 1, title: "Написать статью", link: "/create", img: create_post_icon },
    { id: 2, title: "Создать запись в блоге", link: "/create", img: create_post_blog },
    { id: 3, title: "Создать мероприятие", link: "/create", img: create_post_event },
    { id: 4, title: "Создать новость", link: "/create", img: create_post_news },
];
export var ModalCreatePostMain = function (_a) {
    var isActive = _a.isActive, key = _a.key;
    var dispatch = useAppDispatch();
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    return isActive ? (_jsx(ModalBlurWrapper, { children: _jsxs("section", __assign({ className: style.modalCreatePostMain }, { children: [_jsxs("div", __assign({ className: style.titleAndCloseButton }, { children: [_jsx("span", __assign({ className: style.title }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043F\u0443\u0431\u043B\u0438\u043A\u0430\u0446\u0438\u044E" })), _jsx("div", __assign({ onClick: handleCloseModal, className: style.closeModal }, { children: _jsx("img", { className: style.closeIcon, src: close_icon, alt: "close icon" }) }))] })), _jsx("div", __assign({ className: style.buttonsPanel }, { children: buttons.map(function (button) { return (_jsxs(Link, __assign({ onClick: function () { return handleCloseModal(); }, className: style.link, to: button.link }, { children: [_jsx("img", { src: button.img, className: style.linkIcon, alt: "".concat(button.title, "icon") }), _jsx("span", __assign({ className: style.linlTitle }, { children: button.title }))] }), button.id)); }) }))] }), key) })) : null;
};
