var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import style from "./ModalChangeCover.module.css";
import { useAppDispatch } from "../../../redux/store";
import { setActive } from "../../../redux/modalReducer";
import { changeUserCoverTC } from "../../../redux/updateUserReducer";
import { useEffect, useState } from "react";
import { ModalBlurWrapper } from "../../Helpers/ModalBlurWrapper/ModalBlurWrapper";
import FileInput from "./components/FileInput";
import close_icon from "../../../assets/img/close_modal_icon.png";
import ImageCropper from "./components/ImageCropper";
import { useSelector } from "react-redux";
import { NEW_URL } from "../../Card/cardImports";
function convertCurrentCoverUrlToFile(url) {
    return __awaiter(this, void 0, void 0, function () {
        var response, data, metadata, file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.blob()];
                case 2:
                    data = _a.sent();
                    metadata = {
                        type: "image/png",
                    };
                    file = new File([data], "pic", metadata);
                    // console.log("file", file);
                    return [2 /*return*/, file];
            }
        });
    });
}
export var ModalChangeCoverNew = function (props) {
    var isActive = props.isActive;
    var dispatch = useAppDispatch();
    var uploadedCover = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth.userAuthInfo) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.cover; });
    var _a = useState(""), image = _a[0], setImage = _a[1];
    var _b = useState("img-cropped"), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = useState(""), imgAfterCrop = _c[0], setImgAfterCrop = _c[1];
    var _d = useState(null), imageForUploadServer = _d[0], setImageForUploadServer = _d[1];
    useEffect(function () {
        if (uploadedCover) {
            var url = "".concat(NEW_URL).concat(uploadedCover);
            //картинка для предпросмотра
            setImage(url);
            //картинка для кропинга
            setImgAfterCrop(url);
            //файл для отправки на сервер
            convertCurrentCoverUrlToFile(url)
                .then(function (res) {
                setImageForUploadServer(res);
            })
                .catch(function (err) {
                console.log("error uplodad cover", err);
            });
        }
        else {
            setImgAfterCrop(null);
            setImageForUploadServer(null);
            onImageSelected(null);
        }
    }, []);
    var handleCloseModal = function () {
        dispatch(setActive(false));
        setImage(null);
        setCurrentPage("img-cropped");
        setImgAfterCrop(null);
        setImageForUploadServer(null);
    };
    // Invoked when new image file is selected
    var onImageSelected = function (selectedImg) {
        setImage(selectedImg);
        setCurrentPage("crop-img");
    };
    // Generating Cropped Image When Done Button Clicked
    var onCropDone = function (imgCroppedArea) {
        var canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
        var context = canvasEle.getContext("2d");
        var imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(imageObj1, imgCroppedArea.x, imgCroppedArea.y, imgCroppedArea.width, imgCroppedArea.height, 0, 0, imgCroppedArea.width, imgCroppedArea.height);
            var dataURL = canvasEle.toDataURL("image/png", 0.8);
            var imageServ = canvasEle.toBlob(function (blob) {
                var metadata = {
                    type: "jpeg",
                    size: blob.size,
                };
                var imageToServ = new File([blob], "123.jpeg", metadata);
                console.log("imageToServ", imageToServ);
                setImageForUploadServer(imageToServ);
            }, "image/jpeg", 0.6);
            setImgAfterCrop(dataURL);
            setCurrentPage("img-cropped");
        };
    };
    // отменяем кроп
    var onCropCancel = function () {
        setCurrentPage("img-cropped");
    };
    var onSaveImageToServ = function () {
        var cover = new FormData();
        if (imageForUploadServer) {
            cover.append("cover", imageForUploadServer);
            dispatch(changeUserCoverTC(cover));
        }
        else {
            cover.append("cover", "");
            dispatch(changeUserCoverTC(cover));
        }
        dispatch(setActive(false));
    };
    return isActive ? (_jsx(ModalBlurWrapper, __assign({ onClickClose: handleCloseModal }, { children: _jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.modalChangeAvatar }, { children: _jsxs("div", __assign({ className: style.closeAndModalWrapper }, { children: [currentPage === "crop-img" ? (_jsx(ImageCropper, { image: image, onCropDone: onCropDone, onCropCancel: onCropCancel })) : (_jsx("div", __assign({ className: style.wrapper }, { children: image ? (_jsxs(_Fragment, { children: [_jsx("span", __assign({ className: style.previewTitle }, { children: "\u041F\u0440\u0435\u0434\u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440" })), _jsx("img", { src: imgAfterCrop, className: style.croppedImage }), _jsx("span", __assign({ className: style.placeholder }, { children: "\u041F\u043E\u0441\u043B\u0435 \u0441\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u0438\u044F \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0435 \u043F\u043E\u044F\u0432\u0438\u0442\u0441\u044F \u0432 \u0432\u0430\u0448\u0435\u0439 \u0448\u0430\u043F\u043A\u0435 \u043F\u0440\u043E\u0444\u0438\u043B\u044F. \u041C\u043E\u0436\u0435\u0442\u0435 \u043E\u0431\u0440\u0435\u0437\u0430\u0442\u044C \u0435\u0449\u0435 \u0440\u0430\u0437 \u0438\u043B\u0438 \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043D\u043E\u0432\u043E\u0435 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438e." })), _jsxs("div", __assign({ className: style.cropAndCancelButtons }, { children: [_jsx("button", __assign({ onClick: function () {
                                                setCurrentPage("crop-img");
                                            }, className: style.cropButton }, { children: "\u041E\u0431\u0440\u0435\u0437\u0430\u0442\u044C \u0435\u0449\u0435 \u0440\u0430\u0437" })), _jsx(FileInput, { setImage: setImage, onImageSelected: onImageSelected })] })), image ? (_jsx("div", __assign({ className: style.saveButtonWrapper }, { children: _jsx("button", __assign({ className: style.saveButton, onClick: onSaveImageToServ }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })) }))) : null] })) : null }))), _jsx("img", { onClick: handleCloseModal, className: style.modalCloseIcon, src: close_icon, alt: "close icon" })] })) })) }))) : null;
};
