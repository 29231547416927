// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hzs5YJZDfpafdnWgP7No {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 36px;
    box-sizing: border-box;

    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.TfHUXek6Tc0RPIcF7Fdq {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-blur-wrapper);
    backdrop-filter: blur(3px);

}

/* for css transition animations */
.m0fS841Q5cSABfPTreYB {
    opacity: 0;
    transform: scale(0.9);
}

.pynN5l3VoLSwLqw3lwIw {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.RotkDjlwDRNjCGESA30w {
    opacity: 1;
}

.zPBEVCAheQoLM4kJO06s {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

/* for css transition animations */`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalCoockieAgreement/modalCoockie.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;;IAET,aAAa;IACb,sBAAsB;;IAEtB,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;IAC3C,0BAA0B;;AAE9B;;AAEA,kCAAkC;AAClC;IACI,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,wBAAwB;IACxB,0CAA0C;AAC9C;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,0CAA0C;AAC9C;;AAEA,kCAAkC","sourcesContent":[".container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n\n    padding: 36px;\n    box-sizing: border-box;\n\n    z-index: 999;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: var(--modal-blur-wrapper);\n    backdrop-filter: blur(3px);\n\n}\n\n/* for css transition animations */\n.alert-enter {\n    opacity: 0;\n    transform: scale(0.9);\n}\n\n.alert-enter-active {\n    opacity: 1;\n    transform: translateX(0);\n    transition: opacity 300ms, transform 300ms;\n}\n\n.alert-exit {\n    opacity: 1;\n}\n\n.alert-exit-active {\n    opacity: 0;\n    transform: scale(0.9);\n    transition: opacity 300ms, transform 300ms;\n}\n\n/* for css transition animations */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hzs5YJZDfpafdnWgP7No`,
	"overlay": `TfHUXek6Tc0RPIcF7Fdq`,
	"alert-enter": `m0fS841Q5cSABfPTreYB`,
	"alert-enter-active": `pynN5l3VoLSwLqw3lwIw`,
	"alert-exit": `RotkDjlwDRNjCGESA30w`,
	"alert-exit-active": `zPBEVCAheQoLM4kJO06s`
};
export default ___CSS_LOADER_EXPORT___;
