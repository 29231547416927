var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import style from "./cropper.module.css";
import { useAppDispatch } from "../../../../redux/store";
import { setMessageToUserForModal, setModal, setActive } from "../../../../redux/modalReducer";
function FileInput(_a) {
    var onImageSelected = _a.onImageSelected;
    var inputRef = useRef(null);
    var dispatch = useAppDispatch();
    var handleOnChange = function (event) {
        var _a, _b;
        if (event.target.files && event.target.files.length > 0 && ((_b = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.size) < 2097152) {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(event.target.files[0]);
            reader_1.onload = function (e) {
                onImageSelected(reader_1.result);
            };
        }
        else {
            dispatch(setMessageToUserForModal("Файл превышает размер в 2мб. Загрузите файл меньшего размера и попробуйте снова."));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        }
    };
    var onChooseImg = function () {
        inputRef.current.click();
    };
    return (_jsxs("div", __assign({ className: style.chooseImgWrapper }, { children: [_jsx("input", { type: "file", accept: "image/*", ref: inputRef, onChange: handleOnChange, style: { display: "none" } }), _jsx("button", __assign({ className: style.chooseImgButton, onClick: onChooseImg }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u043D\u043E\u0432\u043E\u0435" }))] })));
}
export default FileInput;
