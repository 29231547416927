var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./followsAndFollowersRow.module.css";
import verifed from "../../../../assets/img/official.svg";
import unfollowed_icon from "../../../../assets/img/new_icon_unfollow.svg";
import { NEW_URL, followOrUnFollowAPI } from "../../../../api/API";
import { useAppDispatch } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useRedirectToProfileDependsOnWhoYouAre } from "../../../RedirectToProfileDependsOnWhoYouAre/RedurectToProfilesForCreator";
import { setActive, setAuthModalOpenStatus, setErrorMessageToUserForModal, setModal } from "../../../../redux/modalReducer";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
export var FollowsAndFollowersRowForDesktop = function (_a) {
    var data = _a.data, isNeedUnFollowAndMsgIcon = _a.isNeedUnFollowAndMsgIcon;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var userIsAuth = useSelector(function (state) { return state.auth.isAuth; });
    var avatar = "".concat(NEW_URL).concat(data === null || data === void 0 ? void 0 : data.avatar);
    var redirectToProfile = useRedirectToProfileDependsOnWhoYouAre(data === null || data === void 0 ? void 0 : data.type, data === null || data === void 0 ? void 0 : data.id, data === null || data === void 0 ? void 0 : data.id_full, data === null || data === void 0 ? void 0 : data.visible_menu);
    var queryClient = useQueryClient();
    var unsubscribeFromCompamy = useMutation({
        mutationFn: function (id) { return followOrUnFollowAPI.unsubscribeOnCompany(id); },
        onSuccess: function () {
            //обновляются данные в разделе закладок
            Promise.all([
                queryClient.invalidateQueries("fetchFollowersOrFollowsInModalDesktop"),
                queryClient.invalidateQueries("fetchRightSetInProfile"),
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("BookmarkedPublicationInProfile"),
                queryClient.invalidateQueries("BookmarkedEventsInProfile"),
                queryClient.invalidateQueries("fetchEventsOnEventsPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("fetchArticlesOnArticlesPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("FollowsInProfile"),
                queryClient.invalidateQueries("EventsInProfile"),
            ]);
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate bookmark", err);
        },
    });
    var handleUnsubscribeFromUser = function (id) {
        if (userIsAuth === false) {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
            return;
        }
        unsubscribeFromCompamy.mutate(id);
    };
    var handleCheckDisplayedUserName = function (type) {
        switch (type) {
            case "companyProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.name);
            case "expertProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.first_name, " ").concat(data === null || data === void 0 ? void 0 : data.last_name);
            case "userProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.first_name, " ").concat(data === null || data === void 0 ? void 0 : data.last_name);
            case "discontProfile/":
                return "".concat(data === null || data === void 0 ? void 0 : data.name);
        }
    };
    var handleCloseModal = function () {
        dispatch(setActive(false));
        queryClient.removeQueries(["fetchFollowersOrFollowsInModalDesktop"]);
    };
    return (_jsx("div", __assign({ className: style.FollowsAndFollowersRow }, { children: _jsxs("div", __assign({ className: style.avatarAndNick }, { children: [_jsx("img", { alt: "avatar", className: style.avatar, src: avatar, onClick: function () {
                        navigate(redirectToProfile);
                        handleCloseModal();
                    } }), _jsxs("div", __assign({ className: style.column }, { children: [_jsxs("div", __assign({ className: style.wrapperRow }, { children: [_jsxs("div", __assign({ onClick: function () {
                                        navigate(redirectToProfile);
                                        handleCloseModal();
                                    }, className: style.usernameAndVerifided }, { children: [_jsx("span", __assign({ className: style.nick }, { children: handleCheckDisplayedUserName(data === null || data === void 0 ? void 0 : data.type) })), data.verifided && _jsx("img", { alt: "verifided", className: style.verifided, src: verifed })] })), isNeedUnFollowAndMsgIcon && (_jsx("div", __assign({ className: style.icons }, { children: _jsx("img", { onClick: function () { return handleUnsubscribeFromUser(data === null || data === void 0 ? void 0 : data.id); }, className: style.icon, src: unfollowed_icon, alt: "followed" }) })))] })), _jsx("div", __assign({ className: style.about }, { children: data === null || data === void 0 ? void 0 : data.status }))] }))] })) })));
};
