var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { setCheckFilterProfileButton } from "../../../redux/searchCompanyReducer";
import { useAppDispatch } from "../../../redux/store";
import { ModalBlurWrapper } from "../../Helpers/ModalBlurWrapper/ModalBlurWrapper";
import style from "./modalOpenSubscribesCard.module.css";
import Search from "../../../assets/img/Search.png";
import debounce from "lodash.debounce";
import close_icon from "../../../assets/img/close_modal_icon.png";
import { FollowsAndFollowersRowForDesktop } from "./FollowsAndFollowersRowDesktop/FollowsAndFollowersRow";
import { setActive } from "../../../redux/modalReducer";
import { useSelector } from "react-redux";
import { Loader } from "../../Loader/loader";
import { SearchErrorBlock } from "../../SearchErrorBlock/SearchErrorBlock";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { fetchSearchedFollowersOrFollows } from "../../../redux/reducers/SearchFollowsAndFollowers/SearchFollowsAndFollowers";
import { useParams } from "react-router-dom";
export var ModalOpenSubscribesCard = function (_a) {
    var _b, _c;
    var isActive = _a.isActive;
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    var _d = useState(""), value = _d[0], setValue = _d[1];
    var viewProfileId = useParams().id;
    var ProfileFilterButtons = useSelector(function (state) { return state.searchUsersReducer.filterButtonForProfile; });
    var authorizedUserId = useSelector(function (state) { var _a, _b; return (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.userAuthInfo) === null || _b === void 0 ? void 0 : _b.id; });
    var role = useSelector(function (state) { return state.searchUsersReducer.typeSearchForSubscribers; });
    var userFilter = useSelector(function (state) { return state.searchUsersReducer.userFilter; });
    var changeHandler = function (e) {
        setValue(e.target.value);
    };
    var debouncedChangeHandler = useMemo(function () { return debounce(changeHandler, 300); }, []);
    var checkButton = function (btn) {
        dispatch(setCheckFilterProfileButton(btn));
    };
    var handleCloseModal = function () {
        dispatch(setActive(false));
        queryClient.removeQueries(["fetchFollowersOrFollowsInModalDesktop"]);
    };
    var checkIsOwnProfileOrView = function (pageParam) {
        //если профиль собственный, то в параметры запроса не вкидывается viewProfileId,
        //если наоборот то добавляется viewProfileId и поиск проходит по подпискам просматриваемого пользователя
        if (viewProfileId && +viewProfileId !== authorizedUserId) {
            return fetchSearchedFollowersOrFollows(pageParam, value, role, userFilter, +viewProfileId);
        }
        else {
            return fetchSearchedFollowersOrFollows(pageParam, value, role, userFilter);
        }
    };
    var _e = useInfiniteQuery(["fetchFollowersOrFollowsInModalDesktop", value, role, userFilter, viewProfileId], function (_a) {
        var _b = _a.pageParam, pageParam = _b === void 0 ? 1 : _b;
        return checkIsOwnProfileOrView(pageParam);
    }, {
        getNextPageParam: function (lastPage, allPages) {
            var max_page = lastPage === null || lastPage === void 0 ? void 0 : lastPage.totalpages;
            var next_page = (allPages === null || allPages === void 0 ? void 0 : allPages.length) + 1;
            return next_page <= max_page ? next_page : undefined;
        },
    }), data = _e.data, isFetching = _e.isFetching, fetchNextPage = _e.fetchNextPage, hasNextPage = _e.hasNextPage;
    useEffect(function () {
        return function () {
            setValue("");
        };
    }, []);
    return (isActive && (_jsx(ModalBlurWrapper, { children: _jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.ModalOpenSubscribesCard }, { children: _jsx("div", __assign({ className: style.container }, { children: _jsxs("div", __assign({ className: style.wrapper }, { children: [_jsxs("div", __assign({ className: style.titleAndClose }, { children: [_jsx("span", __assign({ className: style.title }, { children: role === 3 ? "Подписчики" : "Подписки" })), _jsx("img", { onClick: handleCloseModal, className: style.modalCloseIcon, src: close_icon, alt: "close icon" })] })), _jsx("div", __assign({ className: style.search }, { children: _jsxs("div", __assign({ className: style.container_input }, { children: [_jsx("img", { className: style.icon_input, src: Search }), _jsx("input", { className: style.input_Search, onChange: debouncedChangeHandler, placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043C\u044F", type: "text" })] })) })), _jsx("div", __assign({ className: style.navigation }, { children: ProfileFilterButtons === null || ProfileFilterButtons === void 0 ? void 0 : ProfileFilterButtons.map(function (item, index) {
                                return (_jsxs("button", __assign({ onClick: function () {
                                        checkButton(item);
                                    }, className: item.check ? style.nav_active : style.nav }, { children: [item.title === "Все" ? "".concat(item.title, " ").concat(role === 3 ? "подписчики" : "подписки") : item.title, " "] }), index));
                            }) })), _jsxs("div", __assign({ className: style.content }, { children: [((_b = data === null || data === void 0 ? void 0 : data.pages) === null || _b === void 0 ? void 0 : _b.length) !== 0 ? (_jsx(_Fragment, { children: (_c = data === null || data === void 0 ? void 0 : data.pages) === null || _c === void 0 ? void 0 : _c.map(function (page) {
                                        var _a;
                                        return (_a = page === null || page === void 0 ? void 0 : page.results) === null || _a === void 0 ? void 0 : _a.map(function (subscriber, index) {
                                            return _jsx(FollowsAndFollowersRowForDesktop, { data: subscriber, isNeedUnFollowAndMsgIcon: role === 4 }, index);
                                        });
                                    }) })) : (_jsx(SearchErrorBlock, {})), isFetching && _jsx(Loader, {}), hasNextPage && (_jsx("button", __assign({ className: style.moreButton, onClick: function () { return fetchNextPage(); } }, { children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451" })))] }))] })) })) })) })));
};
