// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tGTNSCt_asNpfVn9RVMK {
  height: auto;
  width: 215px;
  position: absolute;
  top: 65px;
  right: 14%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-sizing: border-box;
  z-index: 500;
  padding: 20px 17.5px;
  padding-right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 16px 1px rgba(20, 130, 197, 0.1);
  border-radius: 10px;
}

.jFFUWakuyidXrrQniiZI {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.qPDla9jVqosB7yKu_frk {
  width: 100%;
  display: flex;
  margin: 10px;
  align-items: center;
  text-decoration: none;
  color: var(--main-Gray-1-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.geX5AdrqfVRYvzeRRloQ {
  margin-right: 10px;
}



@media(min-width: 1241px) {
  .tGTNSCt_asNpfVn9RVMK {
    right: 9%;
  }
}

@media(max-width: 1240px) {
  .tGTNSCt_asNpfVn9RVMK {
    right: 14%;
  }
}

@media(max-width: 960px) {
  .tGTNSCt_asNpfVn9RVMK {
    right: 2.2%;
  }
}

@media (max-width: 480px) {
  .tGTNSCt_asNpfVn9RVMK {
    top: 70px;
    right: 2%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalCreatePostMini/modalCreatePostMini.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,qCAAqC;EACrC,oDAAoD;EACpD,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,qBAAqB;EACrB,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;;;AAIA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,SAAS;IACT,SAAS;EACX;AACF","sourcesContent":[".modalMini {\n  height: auto;\n  width: 215px;\n  position: absolute;\n  top: 65px;\n  right: 14%;\n  display: flex;\n  flex-direction: column;\n  background: #ffffff;\n  box-sizing: border-box;\n  z-index: 500;\n  padding: 20px 17.5px;\n  padding-right: 5px;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  box-shadow: 0px 4px 16px 1px rgba(20, 130, 197, 0.1);\n  border-radius: 10px;\n}\n\n.linkWrapper {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.link {\n  width: 100%;\n  display: flex;\n  margin: 10px;\n  align-items: center;\n  text-decoration: none;\n  color: var(--main-Gray-1-color);\n  font-size: 14px;\n  font-weight: 500;\n  cursor: pointer;\n}\n\n.icon {\n  margin-right: 10px;\n}\n\n\n\n@media(min-width: 1241px) {\n  .modalMini {\n    right: 9%;\n  }\n}\n\n@media(max-width: 1240px) {\n  .modalMini {\n    right: 14%;\n  }\n}\n\n@media(max-width: 960px) {\n  .modalMini {\n    right: 2.2%;\n  }\n}\n\n@media (max-width: 480px) {\n  .modalMini {\n    top: 70px;\n    right: 2%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalMini": `tGTNSCt_asNpfVn9RVMK`,
	"linkWrapper": `jFFUWakuyidXrrQniiZI`,
	"link": `qPDla9jVqosB7yKu_frk`,
	"icon": `geX5AdrqfVRYvzeRRloQ`
};
export default ___CSS_LOADER_EXPORT___;
