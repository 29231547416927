var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { setActive, setAuthModalOpenStatus } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import { ModalBlurWrapper } from "../../Helpers/ModalBlurWrapper/ModalBlurWrapper";
import style from "./ModalErrorAlert.module.css";
import close_icon from "../../../assets/img/close_modal_icon.png";
var ModalErrorAlert = function (_a) {
    var Error = _a.Error, isActive = _a.isActive, MessageToUser = _a.MessageToUser;
    var dispatch = useAppDispatch();
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    var handleToAuthModal = function () {
        dispatch(setActive(false));
        if (MessageToUser === "Вы ввели неправильный пароль или e-mail. Попробуйте еще раз.") {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
        }
    };
    return (isActive && (_jsx(ModalBlurWrapper, { children: _jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.modalErrorAlert }, { children: _jsxs("div", __assign({ className: style.content }, { children: [_jsx("div", __assign({ onClick: handleCloseModal, className: style.modalClose }, { children: _jsx("img", { className: style.modalCloseIcon, src: close_icon, alt: "close icon" }) })), _jsx("div", __assign({ className: style.messageAndIcon }, { children: _jsx("span", __assign({ className: style.messageToUser }, { children: (MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.detail) !== undefined ? MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.detail : typeof MessageToUser === "string" && (MessageToUser === null || MessageToUser === void 0 ? void 0 : MessageToUser.length) < 500 ? MessageToUser : "Упс.Что-то пошло не так..." })) })), _jsx("button", __assign({ onClick: function () { return handleToAuthModal(); }, className: style.redirectTo }, { children: "\u041F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C \u0435\u0449\u0435 \u0440\u0430\u0437" }))] })) })) })));
};
export default ModalErrorAlert;
