// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1400px) {
  .RoN7L6ebQD2vxhKiLjOX {
    flex-direction: column;
    width: 100%;
    height: fill-available;
    align-items: center;
    display: flex;
    background-color: rgba(238, 245, 249, 100%);
    opacity: 1;
    z-index: 100;
    position: fixed;
    top: 60px;
  }

  .HMtp0t8JsHBuimLuILok {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .RflMHYwDkHssTiPRttWV {
    height: 17px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalEditingUserMobile/modalEditingUserMobile.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;IACtB,WAAW;IACX,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,2CAA2C;IAC3C,UAAU;IACV,YAAY;IACZ,eAAe;IACf,SAAS;EACX;;EAEA;IACE,aAAa;IACb,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":["@media (max-width: 1400px) {\n  .ModalEditingUserMobile {\n    flex-direction: column;\n    width: 100%;\n    height: fill-available;\n    align-items: center;\n    display: flex;\n    background-color: rgba(238, 245, 249, 100%);\n    opacity: 1;\n    z-index: 100;\n    position: fixed;\n    top: 60px;\n  }\n\n  .closeModal {\n    display: flex;\n    width: 100%;\n    height: 50px;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n  }\n\n  .closeIcon {\n    height: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalEditingUserMobile": `RoN7L6ebQD2vxhKiLjOX`,
	"closeModal": `HMtp0t8JsHBuimLuILok`,
	"closeIcon": `RflMHYwDkHssTiPRttWV`
};
export default ___CSS_LOADER_EXPORT___;
