var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./ModalMobileFilterForCompanies.module.css";
import { HiChevronLeft } from "react-icons/hi";
import { useEffect, useState } from "react";
import { setActive } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import { useGetCompaniesSearchBlockData } from "../../../hooks/pagination/components/useGetCompaniesSearchBlockData/useGetCompaniesSearchBlockData";
import { resetButtonsInAllCompanyAndExpertPage, setCheckFilterCompaniesButton, setSerchCompanyValue } from "../../../redux/searchCompanyReducer";
import { ConpaniesSearchResult } from "../../../pages/CompaniesSearchPage/CompaniesSerchResult/companiesSeractResultBlock";
import { Loader } from "../../Loader/loader";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export var ModalMobileFilterForCompanies = function (_a) {
    var isActive = _a.isActive;
    var dispatch = useAppDispatch();
    var location = useLocation();
    var filterButtonForCompanies = useSelector(function (state) { return state.searchUsersReducer.filterButtonForCompanies; });
    var _b = useState(""), value = _b[0], setValue = _b[1];
    var _c = useGetCompaniesSearchBlockData(), data = _c.data, isFetching = _c.isFetching, hasNextPage = _c.hasNextPage, fetchNextPage = _c.fetchNextPage;
    useEffect(function () {
        if (location.pathname !== "/companies_page/")
            handleCloseFilter();
    }, [location]);
    useEffect(function () {
        dispatch(setSerchCompanyValue({ searchCompanyValue: "" }));
    }, []);
    var handleCheckSortButton = function (id, type) {
        dispatch(setCheckFilterCompaniesButton({ id: id }));
    };
    var handleCloseFilter = function () {
        dispatch(resetButtonsInAllCompanyAndExpertPage());
        dispatch(setActive(false));
    };
    var onChangeValue = function (value) {
        setValue(value);
        dispatch(setSerchCompanyValue({ searchCompanyValue: value }));
    };
    return isActive ? (_jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.ModalOpenSubscribesCard }, { children: _jsxs("div", __assign({ className: style.containerModal }, { children: [_jsxs("div", __assign({ className: style.companiesSerchBlock }, { children: [_jsxs("div", __assign({ className: style.inputAndCloseWrapper }, { children: [_jsx(HiChevronLeft, { color: "gray", fontSize: 25, onClick: handleCloseFilter }), _jsx("input", { onChange: function (event) {
                                        onChangeValue(event.target.value);
                                    }, className: style.input_Search, placeholder: "\u041F\u043E\u0438\u0441\u043A", type: "text", value: value })] })), _jsx("div", __assign({ className: style.filter_btn_block }, { children: filterButtonForCompanies &&
                                (filterButtonForCompanies === null || filterButtonForCompanies === void 0 ? void 0 : filterButtonForCompanies.map(function (item) { return (_jsxs("button", __assign({ onClick: function () {
                                        handleCheckSortButton(item.id, item.type);
                                    }, className: item.check ? style.result_on : style.result_serch_block_button }, { children: [item.title, " "] }))); })) }))] })), _jsx(ConpaniesSearchResult, { data: data }), isFetching && _jsx(Loader, {}), hasNextPage && (_jsx("button", __assign({ className: style.moreButton, onClick: function () { return fetchNextPage(); } }, { children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0435\u0449\u0451" })))] })) }))) : null;
};
